import { Checkbox, Table } from "antd";

function NamePlate() {
  const dataSource = [
    {
      key: "1",
      name: "Serial Number",
      value: "",
    },
    {
      key: "2",
      name: "Vessel Number",
      value: "",
    },

    {
      key: "3",
      name: "Address",
      value: "",
    },
    {
      key: "4",
      name: "Company ID",
      value: "",
    },
  ];

  const columns = [
    {
      title: "Display Name / ID Short",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "name",
      key: "name",
    },
    {
      dataIndex: "",
      key: "",
      width: 10,
      render: () => <Checkbox />,
    },
  ];

  return (
    <Table
      showHeader={false}
      size="small"
      bordered
      dataSource={dataSource}
      columns={columns}
      pagination={false}
    />
  );
}

export default NamePlate;
