import "./styles.scss";
import { Button, DatePicker, Input, Select } from "antd";
import { IoRefreshOutline } from "react-icons/io5";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/pageHeader";
import DataTable from "./components/dataTable";

function SubModelScreen() {
  const navigate = useNavigate();
  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Submodels ( 275 )"}
          breadcum={"Dashboard / Submodels"}
        >
          <div>
            <DatePicker />
          </div>
          <div>
            <Select placeholder="Type" style={{ width: 100 }}>
              <Select.Option>Type -1</Select.Option>
              <Select.Option>Type -2</Select.Option>
              <Select.Option>Type -3</Select.Option>
            </Select>
          </div>
          <div>
            <Input placeholder="Search" />
          </div>
          <div>
            <Button>
              <IoRefreshOutline size={17} />
            </Button>
          </div>
          <div>
            <Button type="primary">New</Button>
          </div>
        </PageHeader>
        <DataTable />
      </Container>
    </main>
  );
}

export default SubModelScreen;
