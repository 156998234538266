import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import { BsDatabase } from "react-icons/bs";
import { TbCategory } from "react-icons/tb";
import { LuUserCog2 } from "react-icons/lu";

import img1 from "../../../assets/images/icon1.png";
import img2 from "../../../assets/images/icon2.png";
import img3 from "../../../assets/images/icon3.png";
import img4 from "../../../assets/images/icon4.png";
import img5 from "../../../assets/images/icon5.png";
import img6 from "../../../assets/images/icon6.png";
import img7 from "../../../assets/images/icon7.png";
import img9 from "../../../assets/images/icon8.png";
import img10 from "../../../assets/images/icon9.png";
import img11 from "../../../assets/images/icon10.png";
import img12 from "../../../assets/images/icon11.png";

import Card1 from "./components/card1";
import Card2 from "./components/card2";
import Card3 from "./components/card3";

function DashBoard() {
  const cardData1 = [
    {
      title: "Digital Twins",
      icon: <img src={img1} alt="" />,
      value: 3457,
      navigate: "/auth/dgTwin",
    },
    {
      title: "Submodels",
      icon: <img src={img2} alt="" />,
      value: 3457,
      color: "#ffffff",
      navigate: "/auth/SubModelScreen",
    },
    {
      title: "Submodel Templates",
      icon: <img src={img3} alt="" />,
      value: 3,
      navigate: "/auth/SubModelTemplates",
    },
    {
      title: "Digital Twin Templates",
      icon: <img src={img4} alt="" />,
      value: 3,
      navigate: "/auth/dgTwinTemplates",
    },
  ];

  const cardData2 = [
    {
      title: "Product Passports",
      icon: <img src={img5} />,
      navigate: "https://product-passport.straccoon.com",
    },
    {
      title: "Search Analytics",
      icon: <img src={img6} />,
    },
    {
      title: "Portal",
      icon: <img src={img7} />,
      navigate: "https://www.pfeiffer-vacuum.com/global/en/products",
    },
  ];

  const cardData3 = [
    {
      title: "Authorization",
      icon2: <img src={img9} />,
      alternative: "Permission",
    },
    {
      title: "Application Settings",
      icon2: <img src={img10} />,
      alternative: "Preferences",
    },
    {
      title: "Network Enablement",
      icon2: <img src={img11} />,
      alternative: "Network Activation",
    },
    {
      title: "Workflow Management",
      icon2: <img src={img12} />,
      alternative: "Process Automation",
    },
  ];

  return (
    <div>
      <br />
      <Container fluid>
        <div className="dashboard-row">
          <div className="dashboard-text2">Welcome Back Chris 👋 ,</div>
          <div style={{ flex: 1 }} />
          <div className="dashboard-text4">{new Date().toDateString()}</div>
        </div>
        <br />
        <br />
        <div className="dashboard-cardHeadingHead">
          <div className="dashboard-iconBox">
            <BsDatabase color="#087ea4" size={25} />
          </div>
          <span className="dashboard-text1">Data Management</span>
        </div>
        <br />
        <Row gutter={[20, 20]} className="mb-5">
          {cardData1?.map((item: any) => {
            return (
              <Col xs={12} sm={12} md={7} lg={4} xl={4}>
                <Card1
                  title={item.title}
                  icon={item.icon}
                  description={item.description}
                  value={item.value}
                  color={item.color}
                  navigate={item.navigate}
                />
              </Col>
            );
          })}
        </Row>
        <div className="dashboard-cardHeadingHead">
          <div className="dashboard-iconBox">
            <TbCategory color="#087ea4" size={20} />
          </div>
          <span className="dashboard-text1">Applications</span>
        </div>
        <br />

        <Row gutter={[20, 20]} className="mb-5">
          {cardData2?.map((item: any) => {
            return (
              <Col xs={12} sm={12} md={7} lg={4} xl={4}>
                <Card2
                  title={item.title}
                  icon={item.icon}
                  color={item.color}
                  navigate={item?.navigate}
                />
              </Col>
            );
          })}
        </Row>
        <div className="dashboard-cardHeadingHead ">
          <div className="dashboard-iconBox">
            <LuUserCog2 color="#087ea4" size={20} />
          </div>
          <span className="dashboard-text1">Administration</span>
        </div>
        <br />

        <Row gutter={[20, 20]} className="mb-5">
          {cardData3?.map((item: any) => {
            return (
              <Col xs={12} sm={12} md={7} lg={4} xl={4}>
                {" "}
                <Card3
                  title={item.title}
                  icon2={item.icon2}
                  alternative={item.alternative}
                  description={item.description}
                  color={item.color}
                  navigate={""}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default DashBoard;
