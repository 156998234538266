import "./styles.scss";
import { Button, Card, Form, Input } from "antd";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import PageHeader from "../../components/pageHeader";

function SubModelTemplateFrom() {
  const navigate = useNavigate();
  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Master Data"}
          breadcum={
            "Dashboard / Submodel Templates / Master Data of Ship 112233 "
          }
        >
          <div>
            <Button type="primary">Save</Button>
          </div>
          <div>
            <Button danger type="text">
              Cancel
            </Button>
          </div>
        </PageHeader>
        <Form layout="vertical">
          <Card title={"Identification Attributes"}>
            <Row>
              <Col sm={6} xs={12}>
                <Form.Item label="Display Name">
                  <Input size="large" placeholder="Enter name" />
                </Form.Item>
                <Form.Item label="ID">
                  <Input size="large" placeholder="Enter ID" />
                </Form.Item>
              </Col>
              <Col sm={6} xs={12}>
                <Form.Item label="Semanitc ID">
                  <Input size="large" placeholder="Enter ID" />
                </Form.Item>
                <Form.Item label="Short Description">
                  <Input size="large" placeholder="Enter Description" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Long Description">
              <Input.TextArea size="large" placeholder="Enter Description" />
            </Form.Item>
          </Card>
          <br />

          <Form.List name={"Users"}>
            {(fields, { add, remove }) => (
              <Card
                title={"Data Elements"}
                extra={
                  <div>
                    <Button type="primary" ghost onClick={() => add()}>
                      Add field
                    </Button>
                  </div>
                }
              >
                <Table bordered>
                  <thead>
                    <tr>
                      <th>ID / Display Name</th>
                      <th>Value</th>
                      <th>Semantic ID</th>
                      <th>Short Description</th>
                      <th>Long Description</th>
                      <th>Type</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ key, name, ...restField }) => (
                      <tr>
                        <td>
                          <Form.Item noStyle>
                            <Input />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item noStyle>
                            <Input />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item noStyle>
                            <Input />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item noStyle>
                            <Input />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item noStyle>
                            <Input />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item noStyle>
                            <Input />
                          </Form.Item>
                        </td>
                        <td>
                          <Button
                            danger
                            onClick={() =>
                              fields?.length > 1 ? remove(name) : null
                            }
                            icon={<IoIosRemoveCircleOutline />}
                          ></Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            )}
          </Form.List>
        </Form>
      </Container>
    </main>
  );
}

export default SubModelTemplateFrom;
