import { message, Table } from "antd";
import { BsCopy } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import data from "./data.json";
function DataTable() {
  const columns = [
    {
      title: "Identification",
      width: 850,
      render: (text: any) => (
        <div>
          <div className="subModalScreen-text1">{text?.name}</div>
          <div className="subModalScreen-text2">{text?.description}</div>
        </div>
      ),
    },
    {
      title: "Semantic ID",
      dataIndex: "semanticId",
      key: "semanticId",
      render: (text: any) => (
        <div>
          <div>
            {text} &nbsp;{" "}
            <BsCopy
              color="green"
              size={15}
              onClick={() => message.success("ID copied")}
            />
          </div>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "age",
      key: "age",
      width: 30,
      render: () => (
        <div>
          <IoIosArrowForward size={20} />
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table size="small" dataSource={data} columns={columns} />
    </div>
  );
}

export default DataTable;
