import { Modal, Table, Checkbox } from "antd";
import data from "../../subModalScreen/components/data.json";
const SubmodalModal = (props: any) => {
  const dataSource = [
    {
      key: "1",
      name: "Master Data",
      value: "12345678900",
    },
    {
      key: "2",
      name: "Technical Data",
      value: "12345678900",
    },
    {
      key: "3",
      name: "Name Plate",
      value: "12345678900",
    },
    {
      key: "4",
      name: "Alert",
      value: "12345678900",
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 10,
      render: () => <Checkbox />,
    },
    {
      title: "Identification",
      render: (text: any) => (
        <div>
          <div className="subModalScreen-text1">{text?.name}</div>
          <div className="subModalScreen-text2">{text?.description}</div>
        </div>
      ),
    },
    {
      title: "Semantic ID",
      dataIndex: "semanticId",
      key: "semanticId",
    },
  ];

  return (
    <Modal
      title="Submodel Templates"
      open={props?.open}
      onOk={() => props?.close()}
      onCancel={() => props?.close()}
      width={900}
    >
      <Table size="small" bordered dataSource={data} columns={columns} />
    </Modal>
  );
};

export default SubmodalModal;
