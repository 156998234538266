import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { Button, Card, Form, Input } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import SubmodalModal from "./components/submodalModal";

import PageHeader from "../../components/pageHeader";
import { useState } from "react";

function DgTwinTemplateFrom() {
  const navigate = useNavigate();
  const [subModal, setSubModal] = useState(false);
  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Create Digital Twin Template"}
          breadcum={"Dashboard / Digital Twin Template / Create"}
        >
          <div>
            <Button type="primary">Save</Button>
          </div>
          <div>
            <Button danger type="text">
              Cancel
            </Button>
          </div>
        </PageHeader>
        <Card
          extra={
            <Button type="primary" ghost onClick={() => setSubModal(!subModal)}>
              Assign Submodel Templates
            </Button>
          }
        >
          <Form layout="vertical">
            <Row>
              <Col sm={6} xs={12}>
                <Form.Item label="Template name">
                  <Input size="large" placeholder="Enter Name" />
                </Form.Item>
              </Col>
              <Col sm={6} xs={12}>
                <Form.Item label="Template Description">
                  <Input size="large" placeholder="Enter Description" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Container>
      {subModal ? (
        <SubmodalModal open={subModal} close={() => setSubModal(!subModal)} />
      ) : null}
    </main>
  );
}

export default DgTwinTemplateFrom;
