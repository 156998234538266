import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Dropdown, Button, MenuProps, Card, message, Tabs } from "antd";
import { BsCopy } from "react-icons/bs";
import type { TabsProps } from "antd";

import PageHeader from "../../components/pageHeader";
import NamePlate from "./componets/namePlate";
import TechnicalData from "./componets/technicalData";
import Documention from "./componets/documention";

import ShareModal from "./componets/shareModal";
import PublishModal from "./componets/publishModal";
import ExportModal from "./componets/exportModal";

function DigitalTwinDetails() {
  const [share, setShare] = useState(false);
  const [publish, setPublish] = useState(false);
  const [exports, setExport] = useState(false);

  const items: MenuProps["items"] = [
    {
      label: <div>Edit</div>,
      key: "1",
    },
    {
      label: <div onClick={() => setShare(!share)}>Share</div>,
      key: "2",
    },
    // {
    //   label: <div onClick={() => setPublish(!publish)}>Publish in Portal</div>,
    //   key: "3",
    // },
    // {
    //   label: <div onClick={() => setExport(!exports)}>Export</div>,
    //   key: "4",
    // },
  ];

  const Tabsitems: TabsProps["items"] = [
    {
      key: "1",
      label: "Nameplate",
      children: <NamePlate />,
    },
    {
      key: "2",
      label: "Technical Data",
      children: <TechnicalData />,
    },
    {
      key: "3",
      label: "Documentation",
      children: <Documention />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Ship 5868"}
          breadcum={"Dashboard / Digital Twins / Ship 5868"}
        >
          <div>
            <Dropdown menu={{ items }} placement="bottomLeft">
              <Button style={{ width: 150 }}>Manage</Button>
            </Dropdown>
          </div>
        </PageHeader>
        <Card>
          <Row>
            <Col sm={2} xs={12}>
              <div className="digitalTwinScreen-image">
                <img src="https://t3.ftcdn.net/jpg/00/41/06/42/360_F_41064239_IaGdGyf1vxHFaNDS5K164OFOwiMe1hC9.jpg" />
              </div>
            </Col>
            <Col sm={6} xs={12}>
              <div className="digitalTwinScreen-text4">Puttenham</div>
              <div className="digitalTwinScreen-text3">-8452</div>
              <div className="digitalTwinScreen-text5">
                hijacking, Mediterranean Sea [1985].Although since the late 20th
                century hijacking most frequently involved the seizure of an
                airplane and its forcible diversion to destinations chosen by
                the air pirates,
              </div>
            </Col>
            <Col sm={1} xs={12}></Col>
            <Col sm={3} xs={12}>
              <div className="digitalTwinScreen-text3">
                ID : 123456789 8452 &nbsp;&nbsp;
                <BsCopy
                  color="green"
                  size={20}
                  onClick={() => message.success("ID copied")}
                />
              </div>
              <div className="digitalTwinScreen-text3">
                Global Asset ID: 58678234345{" "}
              </div>
              <div className="digitalTwinScreen-text3">Specific Asset IDs</div>
            </Col>
          </Row>
        </Card>
        <Tabs defaultActiveKey="1" items={Tabsitems} onChange={onChange} />
      </Container>
      {share ? (
        <ShareModal open={share} close={() => setShare(!share)} />
      ) : null}
      {publish ? (
        <PublishModal open={publish} close={() => setPublish(!publish)} />
      ) : null}
      {exports ? (
        <ExportModal open={exports} close={() => setExport(!exports)} />
      ) : null}
    </main>
  );
}

export default DigitalTwinDetails;
