import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Button, Card, Form, Input, Tabs, TabsProps, Upload } from "antd";

import PageHeader from "../../components/pageHeader";
import SubmodalModal from "./componets/submodalModal";

function DigitalTwinForm() {
  const [subModal, setSubModal] = useState(false);
  const Tabsitems: TabsProps["items"] = [
    {
      key: "1",
      label: "Data Sheet",
      children: <div className="digitalTwinScreen-formBox"></div>,
    },
    {
      key: "2",
      label: "Indicators",
      children: <div className="digitalTwinScreen-formBox"></div>,
    },
    {
      key: "3",
      label: "Alerts",
      children: <div className="digitalTwinScreen-formBox"></div>,
    },
    {
      key: "4",
      label: "Rules",
      children: <div className="digitalTwinScreen-formBox"></div>,
    },
  ];

  const operations = (
    <Button type="primary" ghost onClick={() => setSubModal(!subModal)}>
      Add Submodel Template
    </Button>
  );

  return (
    <main>
      <Container fluid>
        <PageHeader
          title={"Create Digital Twins"}
          breadcum={"Dashboard / Digital Twins / Create"}
        >
          <div>
            <Button type="primary">Save</Button>
          </div>
          <div>
            <Button danger type="text">
              Cancel
            </Button>
          </div>
        </PageHeader>
        <Card>
          <Form layout="vertical">
            <Row>
              <Col sm={2} xs={12}>
                <div style={{ aspectRatio: 1 }}>
                  <Upload.Dragger>
                    <AiOutlineCloudUpload size={30} />
                    <p className="ant-upload-hint">
                      Click or drag file to this area to upload
                    </p>
                  </Upload.Dragger>
                </div>
              </Col>
              <Col sm={5} xs={12}>
                <Form.Item label="Display Name">
                  <Input size="large" placeholder="Enter name" />
                </Form.Item>
                <Form.Item label="Short Description">
                  <Input size="large" placeholder="Enter Description" />
                </Form.Item>
                <Form.Item label="Long Description">
                  <Input.TextArea
                    size="large"
                    placeholder="Enter Description"
                  />
                </Form.Item>
              </Col>
              <Col sm={5} xs={12}>
                <Form.Item label="ID">
                  <Input size="large" placeholder="Enter ID" />
                </Form.Item>
                <Form.Item label="Global Asset ID">
                  <Input size="large" placeholder="Enter ID" />
                </Form.Item>
              </Col>
            </Row>
            <Tabs
              defaultActiveKey="1"
              items={Tabsitems}
              tabBarExtraContent={operations}
            />
          </Form>
        </Card>
      </Container>
      {subModal ? (
        <SubmodalModal open={subModal} close={() => setSubModal(!subModal)} />
      ) : null}
    </main>
  );
}

export default DigitalTwinForm;
