import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    auth: false,
  },
  reducers: {
    isLoggedIn: (state) => {
      state.auth = true;
    },
    isLoggedOut: (state) => {
      state.auth = false;
    },
  },
});

export const { isLoggedIn, isLoggedOut } = authSlice.actions;
export default authSlice.reducer;
