import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { RiTwitterXLine } from "react-icons/ri";
import { PiLinkedinLogoFill } from "react-icons/pi";
import { FaYoutube } from "react-icons/fa";
function Footer() {
  return (
    <div className="Footer">
      <Container>
        <Row>
          <Col sm={3} xs={12}>
            <div className="Footer-box1">About</div>
          </Col>
          <Col sm={3} xs={12}>
            <div className="Footer-box1">Contact Us</div>
          </Col>
          <Col sm={3} xs={12}>
            <div className="Footer-box1">Support</div>
          </Col>
          <Col sm={3} xs={12}>
            <div className="Footer-box2">
              <div>
                <RiTwitterXLine size={20} />
              </div>
              <div>
                <PiLinkedinLogoFill size={25} />
              </div>
              <div>
                <FaYoutube size={25} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
