import React, { ReactNode } from "react";
import "../styles.scss";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  icon: ReactNode;
  description: string;
  value: number;
  color: string;
  navigate: string;
}
function Card1({ title, icon, description, value, color, navigate }: Props) {
  const navigation = useNavigate();
  return (
    <div
      onClick={() => navigation(navigate)}
      className="dashboard-card1-cardHead"
    >
      <div className="dashboard-card1-cardBox">
        <div className="dashboard-card1-title">{title}</div>
        <div className="dashboard-card1-iconHead">{icon}</div>
      </div>
      <div className="dashboard-card1-cardBox2">
        <div className="dashboard-card1-valTxt">{value}</div>
      </div>
    </div>
  );
}

export default Card1;
