import { Button, Card, Table } from "antd";
import { useState } from "react";

function NamePlate() {
  const [extend, setExtend] = useState(false);

  const dataSource = [
    {
      key: "1",
      name: "Serial Number",
      age: 32,
      address: "Street Code",
      address_value: "West kaithapoyil",
    },
    {
      key: "2",
      name: "Vessel Number",
      age: 42,
      address: "PIN",
      address_value: "673586",
    },

    {
      key: "2",
      name: "Address",
      age: 42,
      address: "City",
      address_value: "Calicut",
    },
    {
      key: "1",
      name: "Company ID",
      age: 32,
    },
  ];

  const columns = [
    {
      title: "Display Name / ID Short",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "name",
      key: "name",
    },
  ];

  const columnsexpand = [
    {
      title: "Display Name / ID Short",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Value",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Semantic ID",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "API End Point",
      dataIndex: "name",
      key: "name",
    },
  ];

  const columnsAddress = [
    {
      title: "",
      dataIndex: "address",
      key: "address",
      render: (Value: any) => <div>{Value}</div>,
    },
    {
      title: "",
      dataIndex: "address_value",
      key: "address_value",
      render: (Value: any) => <div>{Value}</div>,
    },
  ];

  return (
    <Card
      title={"Nameplate"}
      extra={
        <div className="digitalTwinScreen-rowBox">
          <div>
            <Button
              size="small"
              type="primary"
              ghost
              onClick={() => setExtend(!extend)}
            >
              {extend ? "Compact Mode" : "Extended View"}
            </Button>
          </div>
          <div>
            <Button size="small" type="primary" ghost>
              Edit
            </Button>
          </div>
        </div>
      }
    >
      <Table
        size="small"
        bordered
        dataSource={dataSource}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              showHeader={false}
              bordered
              size="small"
              pagination={false}
              columns={columnsAddress}
              dataSource={dataSource}
            />
          ),
          rowExpandable: (record) => record.name === "Address",
        }}
        columns={extend ? columnsexpand : columns}
      />
    </Card>
  );
}

export default NamePlate;
