import { message, Table } from "antd";
import { LuShip } from "react-icons/lu";
import { BsCopy } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Data from "../data/data.json";
function DataTable() {
  const navigate = useNavigate();

  const detailScreen = (id: any) => {
    navigate(`/auth/DgTwinDetails/${id}`);
  };

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: 50,
      render: (text: any) => (
        <div className="digitalTwinScreen-col1" onClick={() => detailScreen(1)}>
          <LuShip />
        </div>
      ),
    },
    {
      title: "Identification",
      width: 850,
      render: (text: any) => (
        <div className="digitalTwinScreen-col2" onClick={() => detailScreen(1)}>
          <div className="digitalTwinScreen-text1">{text?.ship}</div>
          <div className="digitalTwinScreen-text2">{text?.description}</div>
        </div>
      ),
    },
    // {
    //   title: "Global Asset ID",
    //   dataIndex: "age",
    //   key: "age",
    //   render: (text: any) => (
    //     <div className="digitalTwinScreen-col2">
    //       <div>
    //         58678234345 &nbsp;{" "}
    //         <BsCopy
    //           color="green"
    //           size={15}
    //           onClick={() => message.success("ID copied")}
    //         />
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "",
      dataIndex: "globalAssetId",
      key: "globalAssetId",
      width: 30,
      render: (text: any) => (
        <div
          className="digitalTwinScreen-col3"
          onClick={() => detailScreen(text)}
        >
          <IoIosArrowForward size={20} />
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table size="small" dataSource={Data} columns={columns} />
    </div>
  );
}

export default DataTable;
